














import { defineComponent, nextTick, reactive, ref } from "@vue/composition-api";
import { EmailEditor } from "vue-email-editor";

export default defineComponent({
  components: {
    EmailEditor,
    MHandlebars: () => import("@/components/molecules/m-handlebars.vue"),
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    variablesActive: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props, { emit }) {
    const editor = ref<any>(null);

    // Mail editor config

    const state = reactive({
      editor: {
        minHeight: "70vh",
        height: "100%",
        locale: "pl-PL",
        appearance: {
          theme: "light",
          panels: {
            tools: {
              dock: "right",
            },
          },
        },
      },
    });

    const emitValue = () => {
      if (editor.value && editor.value.editor)
        editor.value.editor.exportHtml((value: any) => {
          emit("input", value);
        });
    };

    const onUpdate = () => {
      emitValue();
    };

    const onLoad = () => {
      if (!editor.value || !editor.value.editor) {
        nextTick(onLoad);
        return;
      }

      if (props.value && props.value.design)
        editor.value.editor.loadDesign(props.value.design);
      editor.value.editor.addEventListener("design:updated", onUpdate);
      emitValue();
    };

    return { editor, state, onLoad };
  },
});
